<template>
  <b-row class="justify-content-md-center">
    <b-col md="6">
      <text-terms-and-condition @redirect="redirect" class="d-none"/>

      <b-modal
        v-model="modal_check_email"
        size="lg"
        centered
        hide-footer
        title="Activa tu cuenta"
        @hidden="modalClosed"
      >
        <div class="demo-inline-spacing pb-2">
          <feather-icon icon="MailIcon" size="32" />
          <feather-icon icon="ArrowRightIcon" size="32" />
          <feather-icon icon="CheckCircleIcon" size="32" />
        </div>
        <b-card-text class="text-dark">
          Estás a un paso de poder empezar, ve a tu correo electrónico
          <strong>{{ email }}</strong> y da clic en el correo de confirmación
          para activar tu cuenta.
        </b-card-text>
        <b-card-text
          class="text-dark"
        >
          Revisa tu bandeja de spam en caso que no veas el correo en tu Bandeja
          de Entrada.
        </b-card-text>
        <div class="block">
          <b-button
            @click="resend()"
            variant="no-outline"
            size="lg"
            class="mt-1 no-outline"
            >Reenviar email</b-button
          >
          <b-button @click="`${is_realty ? nextRealty() : next()}`" variant="primary" size="lg" class="mt-1"
            >Perfecto</b-button
          >
        </div>
      </b-modal>
      <modal-redirect :show_modal="show_modal" :key="change_modal">
        <template #body>
          <div v-if="utils.getUserData().main_group.name !== 'creator'">
            <b-button class="col-12 btn-redirect" variant="btn-redirect" @click="$router.push({name: 'home_search'});">{{ $t('goProposal') }}</b-button>
            <b-button class="col-12 btn-redirect" variant="btn-redirect" @click="$router.push({name: 'my_work_brands', params: {section: 'campaigns'}});">{{ $t('goCreateCampaign') }}</b-button>
            <b-button class="col-12 btn-redirect" variant="btn-redirect" @click="$router.push({name: 'analyzer'});">{{ $t('goSearch') }}</b-button>
            <b-button class="col-12 btn-redirect" variant="btn-redirect" @click="$router.push({name: 'campaigns'});">{{ $t('goCampaign') }}</b-button>
          </div>
          <div v-else>
            <b-button class="col-12 btn-redirect" variant="btn-redirect" @click="$router.push({name: 'casting_calls'});">{{ $t('pitch_brands_go_modal') }}</b-button>
            <b-button class="col-12 btn-redirect" variant="btn-redirect" @click="$router.push({name: 'my_work', params: {section: 'proposals'}})">{{ $t('create_proposal_go_modal') }}</b-button>
            <b-button class="col-12 btn-redirect" variant="btn-redirect" @click="$router.push({name: 'dashboard-analytics'});">{{ $t('go_to_home') }}</b-button>
          </div>
        </template>
      </modal-redirect>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCardText,
  BRow,
  BCol,
  BButton,
} from "bootstrap-vue";
import service from "@/services/others";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import config from "@/services/config";
import { initialAbility } from "@/libs/acl/config";
import useJwt from "@/auth/jwt/useJwt";
import TextTermsAndCondition from "./TextTermsAndCondition.vue";
import modalRedirect from "@/views/components/modal/modalRedirect.vue";
import utils from '@/libs/utils';
 

export default {
  components: {
    modalRedirect,
    BCardText,
    BRow,
    BCol,
    BButton,
    TextTermsAndCondition
  },
  data() {
    return {
      clicked: false,
      email: "",
      show_modal: false,
      change_modal: false,
      modal_check_email: false,
      is_realty: utils.getUserData() && utils.getUserData().main_group && utils.getUserData().main_group.name === 'realty' ? 'realty' : '',
      utils,
    };
  },
  created() {
    this.email = this.getEmail();
    if (utils.getUserData().main_group.name !== 'creator') this.next();
    else this.showModal();
  },
  mounted() {

  },
  destroyed() {
    const principal_nav = document.querySelector('ul.nav.navbar-nav.d-xl-none');
    if (principal_nav) principal_nav.style.setProperty('display', 'block', 'important')
    const second_nav = document.getElementsByClassName('width-new')[0];
    if (second_nav) second_nav.style.setProperty('display', 'block', 'important');

    const body = document.querySelector('div.app-content.content');
    body.removeAttribute('style');
  },
  methods: {
    modalClosed() {
      this.is_realty ? this.nextRealty() : this.next()
    },
    getEmail() {
      const user = JSON.parse(localStorage.userData);
      return user.email;
    },
    getAcceptTC() {
      return localStorage.accept_tyc !== 'false';
    },
    resend() {
      service
        .sendEmailPass({ email: this.email }, true)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Se ha enviado el link a tu correo.",
              icon: "EditIcon",
              variant: "success",
            },
          });
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Error al enviar link al correo.",
              icon: "EditIcon",
              variant: "danger",
            },
          });
        });
    },
    nextRealty() {
      this.$router.push({name: 'campaigns'})
    },
    next() {

      // if (utils.getUserData().main_group.name === 'creator') {
      //   const user = JSON.parse(localStorage.userData);
      //   const route = getHomeRouteForLoggedInUser(user)
      //   this.$router.push(route);
      // } else {
      //   this.modal_check_email = false;
      // }
      if (this.is_realty) {
        this.show_modal = false
        this.modal_check_email = true;
        this.change_modal = true
      } else {
        this.show_modal = true
        this.change_modal = true
      }
    },
    showModal() {
      this.modal_check_email = true;
    },
    redirect() {
      const user = JSON.parse(localStorage.userData);
 
      this.clicked = true;
      const auth = {
        Authorization: `Bearer ${localStorage.token}`,
      };

      this.$http
        .post(`${config.urlCore}/api/v1/accounts/accept-tyc/`, {
          headers: auth,
        })
        .then((response) => {
          if (response.status !== 200) this.clicked = false;

          localStorage.setItem("accept_tyc", true);

          if (!user.is_verified) {
            this.showModal();
          } else {
            if (user.roll === null) {
              this.$router.push("/roll");
            }
            this.next();
          }
        })
        .catch(() => {
          this.clicked = false;
        });
    },
    deleteAccount() {
      const auth = {
        Authorization: `Bearer ${localStorage.token}`,
      };
      this.$http
        .post(`${config.urlCore}/api/v1/accounts/delete-account/`, {
          headers: auth,
        })
        .then(() => {
          localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
          localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);

          localStorage.removeItem("userData");
          localStorage.removeItem("accept_tyc");

          this.$ability.update(initialAbility);

          this.$router.push("/adios");
        })
        .catch(() => {
          this.clicked = false;
        });
    },
  },
};
</script>

<style>
.no-outline {
  color: rgb(164, 164, 164);
}
</style>
